"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PublishView = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ViewChanged_1 = require("../../../events/project/views/ViewChanged");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var PublishView = function (_ClientProjectCommand) {
  function PublishView() {
    var _this;
    (0, _classCallCheck2.default)(this, PublishView);
    _this = _callSuper(this, PublishView, arguments);
    _this.aggregate = 'project';
    _this.commandName = 'PublishView';
    return _this;
  }
  (0, _inherits2.default)(PublishView, _ClientProjectCommand);
  return (0, _createClass2.default)(PublishView, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var entity = yield CommandReadModels_1.CommandReadModels.view.getById(this.entityId);
        yield _superPropGet(PublishView, "checkProjectStatusAndGeneralAccess", this, 3)([entity.projectId, userId]);
        if (entity.createdBy === userId && !this.data.isPublic) return true;
        if (!(yield RightsManager_1.RightsManager.hasWriteRight(entity.projectId, userId, 'commandChangeProjectTemplateStatus'))) {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var entity = yield CommandReadModels_1.CommandReadModels.view.getById(this.entityId);
        return new ViewChanged_1.ViewChanged(this.entityId, {
          isPublic: this.data.isPublic
        }, entity.projectId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* () {});
      function validate() {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.PublishView = PublishView;