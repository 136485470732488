var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Header = Header;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ResizeEvent = require("../../ResizeEvent");
var _ThemeManager = require("../../ThemeManager");
var _SearchBar = require("../SearchBar");
var _TabBar = require("../tabs/TabBar");
var _MaterialText = require("../text/MaterialText");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function Header(props) {
  var _props$searchBarOnly;
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    searchBarFocused = _useState2[0],
    setSearchBarFocused = _useState2[1];
  var _useState3 = (0, _react.useState)(0),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    tabsWidth = _useState4[0],
    setTabsWidth = _useState4[1];
  (0, _react.useEffect)(function () {
    var searchBarProps = props.searchBarProps;
    if (searchBarFocused) searchBarProps == null || searchBarProps.onFocus == null || searchBarProps.onFocus();else searchBarProps == null || searchBarProps.onBlur == null || searchBarProps.onBlur();
  }, [searchBarFocused]);
  var onFocusSearchBar = function onFocusSearchBar() {
    return setSearchBarFocused(true);
  };
  var onBlurSearchBar = function onBlurSearchBar() {
    return setSearchBarFocused(false);
  };
  var leftButtons = props.leftButtons,
    rightButtons = props.rightButtons,
    title = props.title,
    _props$backgroundColo = props.backgroundColor,
    backgroundColor = _props$backgroundColo === void 0 ? _ThemeManager.ThemeManager.style.headerBG : _props$backgroundColo,
    textColor = props.textColor,
    additionalContent = props.additionalContent,
    additionalContentPaddingLeft = props.additionalContentPaddingLeft,
    searchBarProps = props.searchBarProps,
    withElevation = props.withElevation,
    withBorder = props.withBorder,
    subTitle = props.subTitle,
    searchBarWidth = props.searchBarWidth,
    tabBarProps = props.tabBarProps,
    tabActions = props.tabActions,
    titleCentered = props.titleCentered,
    persistRightIcons = props.persistRightIcons;
  var searchBarOnly = (_props$searchBarOnly = props.searchBarOnly) != null ? _props$searchBarOnly : searchBarFocused && _ResizeEvent.ResizeEvent.current.contentWidth <= _ThemeManager.ThemeManager.style.breakpointM;
  var headerHeight = additionalContent != null ? _ThemeManager.ThemeManager.style.headerHeightBig : _ThemeManager.ThemeManager.style.headerHeight;
  var paddingValue = _ThemeManager.ThemeManager.style.appBarContentPaddingValue - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(6);
  var elevation = withElevation === true ? _ThemeManager.ThemeManager.style.elevation4 : {};
  var border = withBorder === false ? {} : {
    borderStyle: 'solid',
    borderColor: _ThemeManager.ThemeManager.style.borderColor,
    borderWidth: 0,
    borderBottomWidth: 1
  };
  var tabBarVisible = tabBarProps != null || tabActions != null && tabActions.length > 0 && !searchBarOnly;
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: Object.assign({}, elevation, border, {
      padding: 0,
      alignSelf: 'stretch',
      alignItems: 'flex-start',
      height: headerHeight,
      overflow: _reactNativeWeb.Platform.OS !== 'ios' ? 'hidden' : 'visible',
      backgroundColor: backgroundColor != null && backgroundColor.length > 0 ? backgroundColor : _ThemeManager.ThemeManager.style.headerBG,
      justifyContent: 'flex-start'
    }),
    children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        overflow: 'hidden',
        alignItems: 'center',
        height: _ThemeManager.ThemeManager.style.headerHeight,
        padding: 0
      },
      children: [leftButtons != null && leftButtons.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          paddingLeft: paddingValue
        },
        children: leftButtons
      }) : null, (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          paddingLeft: 16,
          paddingRight: rightButtons != null && rightButtons.length > 0 ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(4) : _ThemeManager.ThemeManager.style.contentPaddingValue,
          alignItems: 'center',
          justifyContent: titleCentered === true ? 'center' : 'flex-start',
          flexDirection: 'row',
          flexGrow: 1,
          flexShrink: 1,
          height: _ThemeManager.ThemeManager.style.headerHeight,
          overflow: 'hidden'
        },
        children: [title != null && typeof title === 'string' && title.length > 0 && !searchBarOnly && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            marginRight: searchBarProps != null ? 8 : 0
          },
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.H6,
            centeredBox: false,
            centeredText: false,
            color: textColor != null && textColor.length > 0 ? textColor : _ThemeManager.ThemeManager.style.headerTitleColor,
            numberOfLines: 1,
            children: title
          }), subTitle && subTitle.length > 0 && (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body2,
            numberOfLines: 1,
            children: subTitle
          })]
        }), title != null && typeof title !== 'string' && !searchBarOnly && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            marginRight: searchBarProps != null ? 8 : 0
          },
          children: title
        }), tabBarVisible && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1
          },
          children: [tabBarProps != null && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              flex: 1,
              maxWidth: tabBarProps.tabs.length * 264
            },
            onLayout: function onLayout(e) {
              if (e.nativeEvent.layout.width !== tabsWidth) setTabsWidth(e.nativeEvent.layout.width);
            },
            children: (0, _jsxRuntime.jsx)(_TabBar.TabBar, Object.assign({}, tabBarProps, {
              width: tabsWidth
            }))
          }), tabActions != null && tabActions.length > 0 && !searchBarOnly ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingRight: paddingValue,
              flexDirection: 'row',
              maxWidth: '100%'
            },
            children: tabActions
          }) : null]
        }), searchBarProps != null ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            marginVertical: 3,
            paddingRight: searchBarOnly ? 16 : 4,
            maxWidth: searchBarWidth,
            flex: 1
          },
          children: (0, _jsxRuntime.jsx)(_SearchBar.SearchBar, Object.assign({
            onFocus: onFocusSearchBar,
            onBlur: onBlurSearchBar,
            small: true
          }, searchBarProps))
        }) : null]
      }), rightButtons != null && rightButtons.length > 0 && (!searchBarOnly || persistRightIcons) ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          paddingRight: paddingValue,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
        children: rightButtons.map(function (c, i) {
          return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            children: c
          }, `rightButton_${i}`);
        })
      }) : null]
    }), additionalContent != null && !searchBarOnly ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        maxHeight: 72,
        paddingLeft: additionalContentPaddingLeft !== true ? 0 : leftButtons != null && leftButtons.length > 0 ? _ThemeManager.ThemeManager.style.contentPaddingValue + leftButtons.length * 48 : _ThemeManager.ThemeManager.style.contentPaddingValue,
        alignSelf: 'stretch',
        overflow: 'hidden'
      },
      children: additionalContent
    }) : null]
  });
}