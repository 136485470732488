"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.View = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var ViewEntity_1 = require("./entities/ViewEntity");
var View = function (_ReadModelClientOffli) {
  function View() {
    var _this;
    (0, _classCallCheck2.default)(this, View);
    _this = _callSuper(this, View, arguments);
    _this.exampleEntity = new ViewEntity_1.ViewEntity();
    _this.onEvents = [{
      eventName: 'ViewCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ViewChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'ViewRemoved',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(View, _ReadModelClientOffli);
  return (0, _createClass2.default)(View, [{
    key: "onViewRemoved",
    value: function () {
      var _onViewRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        ent.deleted = true;
        ent.lastModifiedAt = e.createdAt;
        return ent;
      });
      function onViewRemoved(_x, _x2) {
        return _onViewRemoved.apply(this, arguments);
      }
      return onViewRemoved;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.View = View;