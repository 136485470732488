var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoButton = InfoButton;
var _react = _interopRequireDefault(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _Routing = require("../routing/Routing");
var _Icon = require("./Icon");
var _ThemeManager = require("../ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function InfoButton(props) {
  var _props$toolTip;
  var icon = props.icon != null ? props.icon : 'information-outline';
  var iconColor = props.color != null ? props.color : _ThemeManager.ThemeManager.style.defaultIconColor;
  var iconSize = props.iconSize != null ? props.iconSize : 24;
  var outerSize = props.outerSize != null ? props.outerSize : iconSize * 1.5;
  var backgroundColor = props.backgroundColor != null ? props.backgroundColor : 'transparent';
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
      toolTip: (_props$toolTip = props.toolTip) != null ? _props$toolTip : '',
      icon: icon,
      onPress: function onPress() {
        var alertConfig = {
          text: props.text
        };
        if (props.title) {
          alertConfig.title = props.title;
        }
        _Routing.Routing.instance.alert.post(alertConfig);
      },
      color: iconColor,
      iconSize: iconSize,
      outerSize: outerSize,
      backgroundColor: backgroundColor
    })
  });
}