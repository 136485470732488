"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _superPropGet(t, o, e, r) { var p = (0, _get2.default)((0, _getPrototypeOf2.default)(1 & r ? t.prototype : t), o, e); return 2 & r && "function" == typeof p ? function (t) { return p.apply(e, t); } : p; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateView = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var Config_1 = require("../../../../Config");
var ViewCreated_1 = require("../../../events/project/views/ViewCreated");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var CreateView = function (_ClientProjectCommand) {
  function CreateView() {
    var _this;
    (0, _classCallCheck2.default)(this, CreateView);
    _this = _callSuper(this, CreateView, arguments);
    _this.aggregate = 'project';
    _this.commandName = 'CreateView';
    return _this;
  }
  (0, _inherits2.default)(CreateView, _ClientProjectCommand);
  return (0, _createClass2.default)(CreateView, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        yield _superPropGet(CreateView, "checkProjectStatusAndGeneralAccess", this, 3)([this.data.projectId, userId]);
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ViewCreated_1.ViewCreated(this.entityId, {
          name: this.data.name,
          type: this.data.type,
          filter: this.data.filter,
          on: this.data.on,
          projectId: this.data.projectId,
          default: this.data.default,
          additionalData: this.data.additionalData
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'name') {
          if (this.data.name == null || this.data.name.length === 0) {
            throw {
              key: 'name',
              message: 'Name wird benötigt',
              messageCode: 'required'
            };
          }
          if (this.data.name.length > 56) {
            throw {
              key: 'name',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        if (key == null || key === 'type') {
          if (this.data.type == null || !ViewCreated_1.ALLOWED_VIEWS.includes(this.data.type)) {
            throw {
              key: 'view',
              message: 'Ansicht wird benötigt',
              messageCode: 'required'
            };
          }
        }
        if (key == null || key === 'on') {
          if (this.data.type == null || !ViewCreated_1.ALLOWED_ON.includes(this.data.on)) {
            throw {
              key: 'on',
              message: 'On wird benötigt',
              messageCode: 'required'
            };
          }
        }
        if (key == null || key === 'filter') {
          if (this.data.filter.length > Config_1.ConfigHandler.config.string_Large) {
            throw {
              key: 'filter',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CreateView = CreateView;